import "./assets/css/style.css";

import ReactLogo from "./assets/images/shopping_app.svg";

function App() {
  return (
    <div>
      {/*  <!--=============== HEADER ===============--> */}
      <header className="header" id="header">
        <nav className="nav container">
          <a href="#" className="nav__logo" style={{ fontSize: "30px" }}>
            MiComet
          </a>

          <div className="nav__menu" id="nav-menu">
            <ul className="nav__list">
              {/* <li className="nav__item">
                <a href="#home" className="nav__link active-link">
                  Home
                </a>
              </li> */}
              <li className="nav__item">
                <a href="#about" className="nav__link">
                  About us
                </a>
              </li>
              <li className="nav__item">
                <a href="#services" className="nav__link">
                  Contact Us
                </a>
              </li>
              <li className="nav__item">
                <a href="#contact" className="nav__link">
                  Support
                </a>
              </li>

              <i
                className="bx bx-toggle-left change-theme"
                id="theme-button"
              ></i>
            </ul>
          </div>

          <div className="nav__toggle" id="nav-toggle">
            <i className="bx bx-grid-alt"></i>
          </div>

          <a
            href="https://apps-dot-allproject-377106.el.r.appspot.com"
            className="button button__header"
          >
            SignIn
          </a>
        </nav>
      </header>

      <main className="main">
        {/* <!--=============== HOME ===============--> */}
        <section className="home section" id="home">
          <div className="home__container container grid">
            <div className="home__data">
              <h1 className="home__title">
                Quality <br /> is the best business plan
              </h1>
              <p className="home__description">
                Create your free account at any time and we will deliver the
                personalized shoping cart app for you.
              </p>

              <a
                href="https://apps-dot-allproject-377106.el.r.appspot.com"
                className="button"
              >
                Create New Account
              </a>
            </div>
            <img src={ReactLogo} alt="React Logo" />
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
